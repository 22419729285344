import { useLocation, Link, useParams } from "react-router-dom";
import "./product.css";
import { useState, useRef } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"; // Import icons from react-icons

const Singleproduct = () => {
  const location = useLocation();
  const data = location.state;


  const product_title= data.product_title;
  const description= data.description;
  const productlocation=data.location;
  const price=data.price;
  const useremail =data.useremail;
  const dateposted =data.dateposted;

  
  const carouselRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollCarousel = (direction) => {
    const carousel = carouselRef.current;
    if (!carousel) return; // Ensure carousel reference is valid
  
    const scrollAmount = 200; // Adjust the scroll amount as needed
    const scrollWidth = carousel.scrollWidth;
    const clientWidth = carousel.clientWidth;
  
    let newPosition = carousel.scrollLeft;
  
    if (direction === "left") {
      newPosition -= clientWidth;
      if (newPosition < 0) {
        newPosition = 0;
      }
    } else if (direction === "right") {
      newPosition += clientWidth;
      if (newPosition + clientWidth > scrollWidth) {
        newPosition = scrollWidth - clientWidth;
      }
    }
  
    carousel.scrollTo({
      left: newPosition,
      behavior: "smooth",
    });
  };
  
  ////the chat functionality
  // const makeid=(length)=> {
  //   let result = '';
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const charactersLength = characters.length;
  //   let counter = 0;
  //   while (counter < length) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     counter += 1;
  //   }
  //   return result;
  // }
  
  // const chatids = makeid(30);

  // const sendmsg = async (e) => {
  //   e.preventDefault();
  //   try {
      
  //     await setDoc(doc(db, "messages", chatids), {
  //       ...data,
  //       timeStamp: serverTimestamp(),
  //       useremail,
  //       useruid
        
  //     });
  //     alert("message sent successfully")
  //     //navigate(-1)
  //   } catch (err) {
  //     console.log(err);
  //     alert(err)
  //   }
  // };


  return (
    <div className="products">
      <Link to={'/post'} className="hover:underline font-bold">Add new post</Link>
      <Link to={'/home'} className="hover:underline font-bold">Home</Link>
      
      {/* Horizontal scrollable carousel */}
      <div className="carousel-container">
        <button className="arrow arrow-left" onClick={() => scrollCarousel("left")}><AiOutlineLeft /></button>
        <div className="carousel" ref={carouselRef}>
          {data.imgs.map((imgUrl, index) => (
            <img key={index} src={imgUrl} alt={`Product ${index + 1}`} className="carousel-image" />
          ))}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollCarousel("right")}><AiOutlineRight /></button>
      </div>

      <p>title: {product_title}</p>
      <p>description: {description}</p>
      <p>location: {productlocation}</p>
      <p>price: {price}</p>
      <p>Posted by : {useremail}</p> 
      <p>Posted : {dateposted}</p> 

      <div>
        <form >
          <input type="text" placeholder="type message" />
          <button type="submit"> send</button>
        </form>
      </div>
    </div>
  );
};

export default Singleproduct;
