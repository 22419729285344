// import Login from "./components/auth/login";
// import Register from "./components/auth/register";
// import Header from "./components/header";
// import Home from "./components/home";
// import { productInputs, signInputs, userInputs } from "./formSource";
// import Post from "./components/post/Post";
// import Products from "./components/products/Products";
// import { AuthProvider } from "./contexts/authContext";
// import { useRoutes } from "react-router-dom";
// import Singleproduct from "./components/singleproduct/Singleproduct";
// import Posttest from "./components/post/Posttest";

// function App() {
//   const routesArray = [
//     {
//       path: "*",
//       element: <Login />,
//     },
//     {
//       path: "/login",
//       element: <Login />,
//     },
//     {
//       path: "/register",
//       element: <Register />,
//     },
//     {
//       path: "/home",
//       element: <Home />,
//     },
//     {
//       path: "/post",
//       element:  <Posttest inputs={productInputs} title="Post New Ad" />,
//     },
//     {
//       path: "/products",
//       element:  <Products/>,
//     },
//     {
//       path: "/product/",
//       element:  <Singleproduct/>,
//     },
//   ];
//   let routesElement = useRoutes(routesArray);
//   return (
//     <AuthProvider>
//       <Header />
//       <div className="w-full h-screen flex flex-col">{routesElement}</div>
//     </AuthProvider>
//   );
// }

// export default App;


import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Header from "./components/header";
import Home from "./components/home";
import { productInputs, signInputs, userInputs } from "./formSource";
import Post from "./components/post/Post";
import Products from "./components/products/Products";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes, Navigate } from "react-router-dom";
import Singleproduct from "./components/singleproduct/Singleproduct";
import Posttest from "./components/post/Posttest";

function App() {
  const routesArray = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/post",
      element: <Posttest inputs={productInputs} title="Post New Ad" />,
    },
    {
      path: "/products",
      element: <Products/>,
    },
    {
      path: "/product/",
      element: <Singleproduct/>,
    },
    // Add other routes as needed
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ];

  let routesElement = useRoutes(routesArray);

  return (
    <AuthProvider>
      <Header />
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
