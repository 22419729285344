// import React from 'react'
// import { useAuth } from '../../contexts/authContext'
// import { Navigate, Link } from 'react-router-dom'
// import Products from '../products/Products'
// const Home = () => {
//     const { currentUser } = useAuth()
//     return (
//         <div className='text-2xl font-bold pt-14'>
//          <div>
//             Hello
            
//              {currentUser.displayName ? currentUser.displayName : currentUser.email}, you are now logged in.
//             </div>
//         <Link to={'/post'} className="hover:underline font-bold">Add new post</Link>
//           <Link to={'/products'} className="hover:underline font-bold">All Products</Link>
//          <br/> 
//          <Products/>
//         </div>

//     )
// }

// export default Home



import React from 'react'
import { useAuth } from '../../contexts/authContext'
import { Link } from 'react-router-dom'
import Products from '../products/Products'

const Home = () => {
    const { currentUser } = useAuth()

    return (
        <div className='text-2xl font-bold pt-14'>
            <div>
                {currentUser ? (
                    <>
                        Hello, {currentUser.displayName ? currentUser.displayName : currentUser.email}, you are now logged in.
                    </>
                ) : (
                    <>
                        <p>Please log in.</p>
                        <Link to={'/post'} className="hover:underline font-bold">Add new post</Link>
                    <Link to={'/products'} className="hover:underline font-bold">All Products</Link>
                    <br/> 
                    <Products/>
                    </>
                )}
            </div>
            {currentUser && (
                <>
                    <Link to={'/post'} className="hover:underline font-bold">Add new post</Link>
                    <Link to={'/products'} className="hover:underline font-bold">All Products</Link>
                    <br/> 
                    <Products/>
                </>
            )}
        </div>
    )
}

export default Home
