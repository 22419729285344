import { useState, useEffect } from "react";
import "./products.css"
import { Link, useNavigate } from 'react-router-dom';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase";

function Products() {
    const [pdts, setPdts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const unsub = onSnapshot(collection(db, "products"), (snapshot) => {
            const productsData = [];
            snapshot.forEach((doc) => {
                const {useremail, dateposted,category,price, location,timeStamp, description, product_title, imgs } = doc.data();
                // Check if imgs is an array and contains at least one element
                const productImages = Array.isArray(imgs) && imgs.length > 0 ? imgs : [];
                productsData.push({
                    id: doc.id,
                    price,
                    location,
                    description,
                    product_title,
                    timeStamp,
                    category,
                    useremail,
                    dateposted,
                    imgs: productImages
                });
            });
            setPdts(productsData);
        }, (error) => {
            console.log(error);
        });

        return () => {
            unsub();
        };
    }, []);

    return (
        <div className='products'>
            <h1>All products</h1>
            <Link to={'/post'} className="hover:underline font-bold">Add new post</Link>
            <Link to={'/home'} className="hover:underline font-bold">Home</Link>
            <div>
                {pdts.map((item) => (
                    <div key={item.id} onClick={() => navigate("/product", { state: item })}>
                        
{item.imgs.length > 0 && <img src={item.imgs[0]} alt="Product" />}  
                        <h1>title:{item.product_title}</h1>
                        <p>description:{item.description}</p>
                        <p>location:{item.location}</p>
                        <p>price:{item.price}</p>
                        <p>category:{item.category}</p>
                        <p>posted by :{item.useremail}</p>
                        <p>Date posted :{item.dateposted}</p> 
                       
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Products;
