import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA4Uv6ZjXpu3gL47kSPd_gTOxYHw44lhkU",
  authDomain: "chipperlands-d20ef.firebaseapp.com",
  projectId: "chipperlands-d20ef",
  storageBucket: "chipperlands-d20ef.appspot.com",
  messagingSenderId: "59376729514",
  appId: "1:59376729514:web:3873fc70e91aa434afffd3",
  measurementId: "G-BHLKVWRT1X"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth,db,storage,firebaseConfig };
