// import "./post.css"
// import { useEffect, useState } from "react";
// import {
//   addDoc,
//   collection,
//   doc,
//   serverTimestamp,
//   setDoc,
//   getDoc
// } from "firebase/firestore";
// import {db, storage } from "../../firebase/firebase";
// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { ref,uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// //import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../contexts/authContext";
// import { Navigate, Link } from 'react-router-dom'
// import Compressor from 'compressorjs'; // Import Compressor from compressorjs

// const Posttest = ({ inputs, title }) => {
//   const [file, setFile] = useState("");
//   const [data, setData] = useState({});
//   const [per, setPerc] = useState(null);
//   //const navigate = useNavigate()
//   const { currentUser } = useAuth()
//   const useremail=currentUser.email;
//   const useruid=currentUser.uid;

// //////product images
//  const [images, setImages] = useState();

// useEffect(() => {
//   const compressAndUpload = async () => {
//     const urls = [];

//     for (let i = 0; i < images.length; i++) {
//       const compressedImage = await compressImage(images[i]); // Compress image
//       const imageRef = ref(storage, `/mulitpleFiles/${images[i].name}`);
//       const uploadTask = uploadBytesResumable(imageRef, compressedImage);

//       try {
//         const snapshot = await uploadTask;
//         const downloadURL = await getDownloadURL(snapshot.ref);
//         urls.push(downloadURL);
//       } catch (error) {
//         console.error('Error uploading image:', error);
//       }
//     }

//     setData((prevData) => ({
//       ...prevData,
//       imgs: urls,
//     }));
//   };

//   if (images) {
//     compressAndUpload();
//   }
// }, [images]);

//    console.log(data);

// // Function to compress an image
// const compressImage = async (image) => {
//   return new Promise((resolve, reject) => {
//     new Compressor(image, {
//       quality: 0.6, // Adjust quality as needed
//       success(result) {
//         resolve(result);
//       },
//       error(e) {
//         console.error('Error compressing image:', e);
//         reject(e);
//       },
//     });
//   });
// };


// /////the whole form
//   const handleInput = (e) => {
//     const id = e.target.id;
//     const value = e.target.value;

//     setData({ ...data, [id]: value });
//   };

//   /////product id
//   function makeid(length) {
//     let result = '';
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     const charactersLength = characters.length;
//     let counter = 0;
//     while (counter < length) {
//       result += characters.charAt(Math.floor(Math.random() * charactersLength));
//       counter += 1;
//     }
//     return result;
//   }
//   const ids = makeid(24);
  
// ////uploading the whole product  to the database
//   // const handleAdd = async (e) => {
//   //   e.preventDefault();
//   //   try {
      

//   //     await setDoc(doc(db, "products", ids), {
//   //       ...data,
//   //       timeStamp: serverTimestamp(),
//   //       useremail,
//   //       useruid
        
//   //     });
//   //     alert("Posttested successfully")
//   //     //navigate(-1)
//   //   } catch (err) {
//   //     console.log(err);
//   //     alert(err)
//   //   }
//   // };
//   const handleAdd = async (e) => {
//     e.preventDefault();
//     try {
//       const productData = {
//         ...data,
//         timeStamp: serverTimestamp(),
//         useremail,
//         useruid,
//         imgs: [], // Initialize an empty array to store image URLs
//       };
  
//       // Add the product data to Firestore
//       const productRef = doc(db, "products", ids);
//       await setDoc(productRef, productData);
  
//       // Update the product document with image URLs
//       const productDocSnapshot = await getDoc(productRef);
//       const productDocData = productDocSnapshot.data();
  
//       const updatedProductData = {
//         ...productDocData,
//         imgs: [...productDocData.imgs, ...data.imgs], // Append new image URLs to existing ones
//       };
  
//       await setDoc(productRef, updatedProductData);
  
//       alert("Product added successfully");
//     } catch (err) {
//       console.error("Error adding product:", err);
//       alert("Error adding product");
//     }
//   };
  

//   return (
   
     
//      <div className="newContainer">
      
//           <div>
//           <Link to={'/products'} className="hover:underline font-bold">All Products</Link> <br/>
//           <Link to={'/home'} className="hover:underline font-bold">Home</Link>
//             <form onSubmit={handleAdd}>
//                <div>
//                 <label htmlFor="file">
//                   Image: 
//                 </label>
               
//                 <input
//                   type="file"
//                   id="file"
//                   multiple
//                   onChange={(e) => setImages(e.target.files)}
//                   style={{ display: "none" }}
//                 />
//               </div>

//               <div className="uploadimg">
                
   
//   </div>

//               {inputs.map((input) => (
//                 <div className="formInput" key={input.id}>
//                   <label>{input.label}</label>
//                   <input
//                     id={input.id}
//                     type={input.type}
//                     placeholder={input.placeholder}
//                     onChange={handleInput}
//                   />
//                 </div>
//               ))}
//               <button className="submitbtn" disabled={per !== null && per < 100} type="submit">
//                 Post
              
//               </button>
//             </form>
//           </div>
       
//       </div>
   

   
//   );
// };

// export default Posttest;
import "./post.css";
import { useEffect, useState } from "react";
import {
  doc,
  serverTimestamp,
  setDoc,
  getDoc
} from "firebase/firestore";
import { db, storage } from "../../firebase/firebase";
import { useAuth } from "../../contexts/authContext";
import { Link } from 'react-router-dom';
import Compressor from 'compressorjs'; // Import Compressor from compressorjs
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Import Firebase Storage functions

const Posttest = ({ inputs, title }) => {
  const [data, setData] = useState({});
  const { currentUser } = useAuth();
  const useremail = currentUser.email;
  const useruid = currentUser.uid;
  const [images, setImages] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);

  useEffect(() => {
    const compressAndUpload = async () => {
      setIsCompressing(true);

      const urls = [];

      for (let i = 0; i < images.length; i++) {
        const compressedImage = await compressImage(images[i]); // Compress image
        const imageRef = ref(storage, `/mulitpleFiles/${images[i].name}`);
        const uploadTask = uploadBytesResumable(imageRef, compressedImage);

        try {
          const snapshot = await uploadTask;
          const downloadURL = await getDownloadURL(snapshot.ref);
          urls.push(downloadURL);
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }

      setData((prevData) => ({
        ...prevData,
        imgs: urls,
      }));

      setIsCompressing(false);
    };

    if (images.length > 0) {
      compressAndUpload();
    }
  }, [images]);

  // Function to compress an image
  const compressImage = async (image) => {
    return new Promise((resolve, reject) => {
      new Compressor(image, {
        quality: 0.6, // Adjust quality as needed
        success(result) {
          resolve(result);
        },
        error(e) {
          console.error('Error compressing image:', e);
          reject(e);
        },
      });
    });
  };

  // Handle form input changes
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  // Generate product ID
  const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const ids = makeid(24);

////the date posted
const getCurrentDateTimeAsString=()=> {
  // Create a new Date object to get the current date and time
  const currentDate = new Date();

  // Extract date components
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
  const day = currentDate.getDate().toString().padStart(2, '0');
  
  // Extract time components
  let hours = currentDate.getHours();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');

  // Construct the short date format string
  const shortDateFormatString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amOrPm}`;

  // Return the short date format string
  return shortDateFormatString;
}

// Example usage:
const dateposted = getCurrentDateTimeAsString();


  // Handle form submission
  const handleAdd = async (e) => {
    e.preventDefault();
    if (isCompressing) {
      alert("Please wait while images are being compressed...");
      return;
    }

    try {
      const productData = {
        ...data,
        useremail,
        useruid,
        dateposted,
        imgs: [],
      };

      // Add the product data to Firestore
      const productRef = doc(db, "products", ids);
      await setDoc(productRef, productData);

      // Update the product document with image URLs
      const productDocSnapshot = await getDoc(productRef);
      const productDocData = productDocSnapshot.data();

      const updatedProductData = {
        ...productDocData,
        imgs: [...productDocData.imgs, ...data.imgs],
      };

      await setDoc(productRef, updatedProductData);

      alert("Product added successfully");
    } catch (err) {
      console.error("Error adding product:", err);
      alert("Error adding product");
    }
  };







  return (
    <div className="newContainer">
      <div>
        <Link to={'/products'} className="hover:underline font-bold">All Products</Link><br />
        <Link to={'/home'} className="hover:underline font-bold">Home</Link>
        <form onSubmit={handleAdd}>
          <div>
            <label htmlFor="file">Image:</label>
            <input
              type="file"
              id="file"
              multiple
              onChange={(e) => setImages(e.target.files)}
              style={{ display: "none" }}
            />
          </div>

          <div className="uploadimg">
            {/* You can display a loading indicator here while images are being compressed */}
          </div>

          {inputs.map((input) => (
            <div className="formInput" key={input.id}>
              <label>{input.label}</label>
              <input
                id={input.id}
                type={input.type}
                placeholder={input.placeholder}
                onChange={handleInput}
              />
            </div>
          ))}
          <button className="submitbtn" type="submit">
            {isCompressing ? "Processing..." : "Post"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Posttest;
