export const signInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  
  {
    id: "email",
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  {
    id: "comfirm_password",
    label: "comfirm_password",
    type: "password",
  },
  
  ];

  export const userInputs = [
    {
      id: "username",
      label: "Username",
      type: "text",
      placeholder: "john_doe",
    },
    {
      id: "displayName",
      label: "Name and surname",
      type: "text",
      placeholder: "John Doe",
    },
    // {
    //   id: "email",
    //   label: "Email",
    //   type: "mail",
    //   placeholder: "john_doe@gmail.com",
    // },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
    },
    {
      id: "address",
      label: "Address",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
    },
    {
      id: "country",
      label: "Country",
      type: "text",
      placeholder: "USA",
    },
  ];
  
  export const productInputs = [
    {
      id:"product_title" ,
      label: "Title",
      type: "text",
      placeholder: "Land",
    },
    {
      id: "location",
      label: "Location",
      type: "text",
      placeholder: "Description",
    },
    {
      id: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    
    {
      id: "category",
      label: "Category",
      type: "text",
      placeholder: "Mailo Land",
    },
    {
      id: "price",
      label: "Price",
      type: "text",
      placeholder: "ugx 100,000,000",
    },
    {
      id: "negotiable",
      label: "Negotiable",
      type: "text",
      placeholder: "Negotiable",
    },
  ];
  